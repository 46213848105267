.index {
  background-color: rgb(255, 255, 255);

  position: sticky;
  top: 78px;

  display: flex;
  padding: 5px;
  flex-direction: row;
  overflow: auto;
}
.index::-webkit-scrollbar {
  display: none;
}
.index a {
  text-decoration: none;
  /* color: rgb(249, 248, 248); */
  /* background-color: rgb(215, 179, 246); */
  font-weight: bold;
  font-size: 0.8rem;
  margin-right: 7px;
  border-radius: 5px;
  padding: 5px;
  /* word-spacing: 2px; */
  letter-spacing: 1px;

}
/* .index a:hover {
  background-color: rgb(22, 22, 16);
  color: rgb(248, 245, 245);
} */
@media (min-width: 767px) {
  .index {
    flex-direction: column;
    position: fixed;
    left: 0;
    width: 100%;
    max-width: 15%;
    padding: 5px 0px;
    overflow: auto;
    max-height: 85vh;
  }

  .index a {
    margin-right: 0px;
    margin-bottom: 5px;
    border-radius: 0px;
    font-size: 0.9rem;
  }
}
