.footer {
  background-color: #060606;
  color: #fff;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.footer-left {
  display: flex;
  align-items: center;
}

.logo {
  width: 50px;
  height: auto;
  margin-right: 10px;
}

.description {
  font-size: 14px;
}

.footer-links {
  display: flex;
}

.social-links {
  list-style-type: none;
  padding: 0;
  /* margin-right: 20px; */
}

.social-links li {
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
}

.social-links a {
  color: #fff;
  font-size: 20px;

}
.social-links a:first-child:hover {
  font-size: 23px;
}

.footer-right {
  display: flex;
}

.footer-menu,
.legal-links {
  list-style-type: none;
  padding: 0;
  margin: 0 20px;
}

.footer-menu li,
.legal-links li {
  margin-bottom: 10px;
}

.footer-menu a,
.legal-links a {
  color: #fff;
  text-decoration: none;
}

.footer-menu a:hover,
.legal-links a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
    .footer {
      padding: 15px;
      display: flex;
      flex-direction: column;
    
    }
  
    .logo {
      width: 60px;
    }
    .footer-links {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
      }
  
    .social-links a {
      font-size: 16px;
    }
    .footer-right {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

  }