.aboutUs a {
  color: black;
}
.aboutUs h1 {
  text-align: center;
}
.alphaRoom {
  background-color: black;
  color: white;
}
.highValueRoom {
  text-align: center;
  font-size: larger;
  padding: 7px;
}
.brder{
   border :1px solid #54c8f2; 
   padding: 5px;
 width: 90%;
  margin: auto;
  }