.homeContainer {
  margin: auto;
  text-align: center;
  align-items: center;
  /* max-width: 70vw; */
  background-color: #fff;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.homeContainer h1{
  background: rgb(63,94,251);
  background: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);
  color: white;
  padding: 1rem;
  border-radius: 7px;
}
.homeContainer a {
  text-decoration: none;
}

.homeBtnBox {
  margin: auto;
  padding: 1rem;
  display: flex;
  border: 2px solid rgb(55, 179, 228);
  background-color: aliceblue;
  border-radius: 8px;
  justify-content: center;
  flex-direction: row;
  /* gap: 1rem; Three columns with equal width */
  flex-wrap: wrap;

  /* width: 70%; */
}
.homeBtn {
  border: none;
  padding: 1rem;
  font-size: 1rem;
  cursor: pointer;
  font-weight: bold;
  border-radius: 7px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.homeBtn:hover {
  background-color: black;
  color: white;
}
.reactBtn {
  background-color: rgb(122, 245, 245);
  color: black;
}
.highValueBtn{
  background-color: black;
  color: rgb(66, 65, 65);
  cursor: pointer;
  animation: colorChange 2s infinite alternate;
}

@keyframes colorChange {
  0% {
    color: grey; /* Start with grey color */
  }
  50% {
    color: white; /* End with white color */
  }
  100% {
    color: grey; /* End with white color */
  }
}
.jsBtn {
  background-color: rgb(249, 239, 104);
  color: black;
}
/* .reactBtn:hover {
  background-color: rgb(15, 96, 96);
}
.jsBtn:hover {
  background-color: rgb(130, 122, 34);
} */
.cssBtn {
  background-color: rgb(225, 138, 246);
}
.htmlBtn {
  background-color: rgb(71, 196, 71);
}
.pythonBtn {
  background-color: rgb(69, 127, 182);
}
.cBtn {
  background-color: grey;
}
.cppBtn {
  background-color: red;
}
.homeTable table {
  border-collapse: collapse;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border-radius: 10px;
  background-color: #fff;
}
.homeTable h2 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 5px 0px;
}
.homeTable th {
  font-size: 18px;
  /* background-color: #a5d2e2; */
  background-color: #a8e3f8;
  padding: 8px 5px;
  text-align: center;
  border: 1px solid #ddd;

  /* background-color: #dbe0e2; */
}
.homeTable td {
  font-size: 14px;
  font-weight: bold;
}
.homeTable td {
  padding: 15px 5px;
  text-align: center;
  border: 1px solid #ddd;
}
@media (max-width: 767px) {
  .homeContainer h1 {
    border-radius:0px;
  }
}