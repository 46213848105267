.nav{
  position: sticky; 
  top: 0;
  z-index: 100;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* margin-bottom: 1rem; */
  text-align: center;
}
.active {
  background-color: #020201;
  color: white;
}
.navbarr {
  /* color: rgb(15, 14, 16); */
  /* background-color:#34e2e2; */
  background-color: #a8e3f8;
  /* border-radius: 5px 5px 0px 0px; */
  padding: 10px 5px;
  /* display: flex; */
  text-align: center;
 
 
}
.logotxt {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  /* color: rgb(243, 246, 88); */
  color: #020201;
  text-decoration: none;
  /* background-color: rgb(185, 178, 178); */
  background-color: rgb(245, 248, 98);
  padding: 5px 15px;
  /* padding: 10px; */
  border-radius: 2px;
  font-size: 15px;
  font-weight: bold;

}
.topics {
  display: flex;
  flex-direction: row;
  /* justify-content: right; */
  /* background-color: #81BECE; */
  background-color: black;
  text-decoration: none;
  /* margin-bottom: 1rem; */
  padding: 7px 5px;
  /* gap: 1rem; */
 
}
.topics a {
  font-weight: bold;
  color: white;
  text-decoration: none;
  /* background-color: rgb(153, 75, 217); */
  padding: 5px;
  border-radius: 3px;
  font-size: 14px;
  margin-right: 1rem;
}
.topics a:hover {
  background-color: rgb(64, 190, 228);
  /* padding: 3px; */
}
