body {
  margin: 0;
  /* font-family:  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;

  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  width: 100vw;
  max-width: 70vw;
  margin: auto;
  color: black;
  background-color: rgb(230, 235, 239);
}
.bContainer {
  /* border: 1px solid rgb(123, 211, 245); */
  /* border-radius: 7px; */
  background-color: #fff;
  padding-top: 1rem;

  margin: auto;
  min-height: 100vh;
}

.container {
  text-align: left;
  font-size: larger;
  padding: 5px;
}
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
@media (max-width: 767px) {
  body {
    max-width: 100vw;
  }
}
.bgYellow {
  background-color: rgb(250, 234, 22);
  padding: 5px;
  border-radius: 5px;
  color: black;
}
.bgWhite {
  background-color: white;
  border: 1px solid black;
  padding: 5px;
}
.bgBlue {
  background-color: rgb(135, 197, 245);
  padding: 5px;
  border-radius: 5px;
}
.bgExample {
  background-color: rgb(246, 249, 246);
  /* background-color: rgb(204, 210, 211); */
  border: 1px solid black;
  padding: 10px;
  border-radius: 5px;
  overflow: auto;
  color: black;
}
.exampleGrd {
  background: rgb(63, 94, 251);
  background: radial-gradient(
    circle,
    rgba(63, 94, 251, 1) 0%,
    rgba(252, 70, 107, 1) 100%
  );
}
.clrRed {
  color: red;
}
.bgRed {
  background-color: red;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.clrBlack {
  background-color: rgb(50, 49, 49);
  padding: 3px 5px;
  color: white;
}
.mAuto {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.widHund {
  width: 100%;
}
.reactExampleBtn{
  background-color: rgb(144, 234, 245);
  font-weight: bold;
  cursor: pointer;
}
